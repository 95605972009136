import React from 'react';
import {Link} from 'gatsby';
import MarkdownView from 'react-showdown';
import Layout from '../../components/Layout';
import SEO from '../../components/seo';
import './style.scss';
import {Row, Col} from 'reactstrap';
import Slider from '../../components/Slider';

export default function CategoryPage(props){
  
  const imageArray = props.pageContext.Image.map(img => {
    img.altText = img.alternativeText;
    img.src = img.url;
    return img;
  });

  return(
    <Layout>
      <SEO
        description={props.pageContext.Description}
        title={`${props.pageContext.Category} - Choice Automotive Equipment`}
      />
      <div className="CategoryPage p-4 bg-light">
        <h3 className="text-center">{props.pageContext.Category}</h3>
        <Row>
          <Col md={6} className="order-xs-2">
          <MarkdownView
            markdown={props.pageContext.Body}
          />
          </Col>
          <Col md={6} className="order-xs-1">
            <Slider 
              items={imageArray} 
              className="mt-auto mb-auto"
            />
          </Col>
        </Row>
        {(
            props.pageContext.sub_categories.length ?
            <h4 className="text-center mt-2">
              Types of {(props.pageContext.Category).toLowerCase()} we offer:
            </h4>
            : null
          )}
        <Row className="justify-content-center">
          {props.pageContext.sub_categories.map((item, index) => (
            <>
              <Link to={`/sub-categories/${item.Slug}`}>
                {item.Category}
              </Link>
              {index !== 0 ? ", " : null}
            </>
          ))}
          {(
            props.pageContext.sub_categories.length ? 
            <Col md={2} sm={4}>
             ...and many more!
            </Col>
            : ''
          )}
        </Row>
      </div>
    </Layout>
  )
};